<!-- 消息 -->
<template>
	<div class="message-page">
		<div class="nav-content flex-jb-ac">
			<div class="nav">
				通知<span v-if="unreadMsgCount > 0">{{ unreadMsgCount }}</span>
			</div>
			<div class="set-btn" @click="readMsg(null)">全部标记为已读</div>
		</div>
		<div class="message-container">
			<div class="message-list">
				<div class="item flex-ac" v-for="item in messageList" :key="item.id" @click="readMsg(item.id, item.is_read)">
					<img :src="item.messageinfo.imageurl" alt="" class="icon" />
					<div class="type">{{ item.messageinfo.name }}</div>
					<div class="content flex1" v-html="item.messageinfo.content" @click="handleClick($event)"></div>
					<div class="status-wrap flex-ac flex-col">
						<div class="status" :class="{ 'has-ready': item.is_read == 1 }">{{ item.is_read == 1 ? "已读" : "未读" }}</div>
						<div class="time">{{ getLocalTime(item.createtime) }}</div>
					</div>
				</div>
			</div>
			<div class="pagination flex-jc-ac">
				<el-pagination
					background
					hide-on-single-page
					next-text="下一页"
					@current-change="getMsgList"
					:current-page.sync="page"
					:page-size="limit"
					prev-text="上一页"
					layout="prev, pager, next"
					:total="total"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { $getMsgList, $sendMsgRead } from "../services/centerInfo.js";
export default {
	name: "Message",
	data() {
		return {
			page: 1,
			limit: 8,
			total: 0,
			messageList: [],
		};
	},
	components: {},

	computed: {
		unreadMsgCount() {
			return this.$store.getters.unreadMsgCount;
		},
	},

	created() {
		this.getMsgList();
	},

	methods: {
		async getMsgList() {
			const { data } = await $getMsgList({
				page: this.page,
				limit: this.limit,
			});
			this.messageList = data.list;
			this.total = data.total;
		},
		async readMsg(id, status) {
			if (status == 1) return;
			const { data } = await $sendMsgRead({ message_ids: id });
			this.getMsgList();
			this.$store.dispatch("getUnreadMsgCount");
		},
		handleClick(e) {
			if (e.target.className == "_localhref") {
				const info = e.target.getAttribute("data-router");
				const { name, params } = eval("(" + info + ")");
				if (name) {
					this.$router.push({ path: name, query: params ? params : {} });
				}
			}
		},
		getLocalTime(timestamp) {
			var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
			let Y = date.getFullYear();
			let M = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
			let D = date.getDate();
			return `${Y}-${M}-${D}`;
		},
	},
};
</script>

<style scoped lang="less">
.message-page {
	border-top: 1px solid #eeefef;
}
.nav-content {
	width: 1200px;
	height: 90px;
	margin: 0 auto;
	border-bottom: 1px solid #eeefef;
	.nav {
		font-size: 18px;
		font-weight: bold;
		color: #1c1f21;
		span {
			display: inline-block;
			padding: 0 6px;
			min-width: 22px;
			height: 22px;
			line-height: 22px;
			text-align: center;
			background: #f54747;
			border-radius: 12px;
			font-size: 16px;
			font-weight: 400;
			color: #ffffff;
			margin-left: 15px;
		}
	}
	.set-btn {
		font-size: 16px;
		font-weight: 400;
		color: #545c63;
		user-select: none;
		cursor: pointer;
		line-height: 42px;
		&:hover {
			color: #15abee;
		}
	}
}
.message-container {
	width: 1200px;
	min-height: calc(100vh - 330px);
	margin: 0 auto;
	background: #e8eaec;
	border-radius: 4px;
	padding-bottom: 40px;
	.message-list {
		.item {
			height: 87px;
			padding: 0 35px;
			&:nth-of-type(2n) {
				background-color: #f3f5f7;
			}
			.icon {
				flex: none;
				width: 46px;
				height: 46px;
				margin-right: 23px;
			}
			.type {
				flex: none;
				font-size: 18px;
				font-weight: bold;
				color: #1c1f21;
				margin-right: 33px;
			}
			.content {
				font-size: 16px;
				font-weight: 400;
				color: #1c1f21;
				/deep/ span._localhref {
					color: #fa7319;
					font-weight: 600;
					margin: 0 3px;
					text-decoration: underline;
					cursor: pointer;
				}
			}
			.status-wrap {
				width: 80px;
				flex: none;
				.status {
					width: 62px;
					height: 22px;
					line-height: 22px;
					text-align: center;
					background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
					border-radius: 4px;
					font-size: 12px;
					color: #ffffff;
					margin-bottom: 5px;
					&.has-ready {
						background: #dbdee1;
						color: #8c9094;
					}
				}
				.time {
					font-size: 14px;
					line-height: 26px;
					color: #545c63;
					white-space: nowrap;
				}
			}
		}
	}
	.pagination {
		margin-top: 50px;
		.el-pagination {
			background: #e8eaec;
			/deep/ button {
				background-color: #e8eaec;
			}
		}
	}
}
</style>
